// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conferences-js": () => import("./../../../src/pages/conferences.js" /* webpackChunkName: "component---src-pages-conferences-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-books-page-js": () => import("./../../../src/templates/books-page.js" /* webpackChunkName: "component---src-templates-books-page-js" */),
  "component---src-templates-conference-js": () => import("./../../../src/templates/conference.js" /* webpackChunkName: "component---src-templates-conference-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-journal-proposals-page-js": () => import("./../../../src/templates/journalProposals-page.js" /* webpackChunkName: "component---src-templates-journal-proposals-page-js" */),
  "component---src-templates-journals-page-js": () => import("./../../../src/templates/journals-page.js" /* webpackChunkName: "component---src-templates-journals-page-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-publish-page-js": () => import("./../../../src/templates/publish-page.js" /* webpackChunkName: "component---src-templates-publish-page-js" */),
  "component---src-templates-support-page-js": () => import("./../../../src/templates/support-page.js" /* webpackChunkName: "component---src-templates-support-page-js" */)
}

